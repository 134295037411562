@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    .inner {
        @extend %page-inner-mobile-no-margin;
    }

    &.withMaxWidth {
        .inner {
            max-width: $max-article-width;
        }
    }
}
