@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';
@import '../commons/animations';

$color-bnc: #e41c23;

.container {
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    > * {
        position: relative;
        z-index: 2;
    }

    * {
        color: $color-white !important;
    }

    &::after {
        @extend %fullscreen;
        content: '';
        display: block;
        z-index: 0;
        background-color: rgba($color-black, 0.8);
    }

    .header {
        font-size: $font-size-medium;
    }

    &.full {
        padding-top: 0;
        padding-bottom: 0;

        .header {
            padding: 10px;
        }
    }

    &.bnc {
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: $color-bnc;
        background-size: contain;

        &::after {
            background-color: rgba($color-bnc, 0.7);
        }

        .header {
            padding-top: 0;
            padding-bottom: 0;
        }

        &.full {
            padding-top: 0;
            padding-bottom: 0;

            .header {
                padding: 20px;
            }
        }
    }
}
