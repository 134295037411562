@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    @extend %fullscreen;

    .loading {
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
    }

    .inner {
        transition: opacity 0.2s ease-out;
    }

    .header {
        position: absolute;
        z-index: $zindex-header;
        top: 0;
        left: 0;
        width: 100%;
    }
}
