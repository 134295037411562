@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    position: fixed;
    z-index: 21;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    .safe {
        margin: 0;
    }

    .inner {
        @extend %fullscreen;

        display: flex;
        overflow: auto;
        flex-direction: column;
        padding: 30px 0;
        transform: translate(0, 30%);
        transition: transform 0.2s ease-in-out;

        > * {
            margin: auto;
        }
    }

    &.opened {
        opacity: 1;

        .inner {
            transform: translate(0, 0);
        }
    }
}
