@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
}

@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}

@mixin item-animation($animation, $duration: 0.2s, $stagger: 0.05s, $count: 10) {
    animation: $animation $duration;
    animation-fill-mode: forwards;

    @for $i from 1 through $count {
        &:nth-child(#{$i}) {
            animation-delay: $i * $stagger;
        }
    }
}

@mixin hover-focus {
    &:hover {
        @content;
    }

    &:focus-visible {
        @content;
    }
}

@keyframes draw-path {
    100% {
        stroke-dashoffset: 0;
    }
}