@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

$header-small-height: 40px;
$header-height: 50px;
$header-sticky-small-height: 40px;
$header-sticky-height: 50px;

.container {
    position: relative;

    .bar {
        display: flex;
        height: $header-small-height;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $color-white;
    }

    .inner {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        padding: 5px 0;
    }

    .link {
        display: block;
        padding-right: 10px;
        padding-left: 5px;
    }

    .logo {
        display: block;
        width: auto;
        height: $header-small-height - 10px;
    }

    &.darkTheme {
        .bar {
            background-color: $color-black;
        }
    }

    &.sticky {
        height: $header-small-height;

        .bar {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: $header-sticky-small-height;
            box-shadow: 0 1px 4px rgba($color-black, 0.2);
        }

        .logo {
            height: $header-sticky-small-height - 10px;
        }
    }

    @media #{$mq-small-screen} {
        .left {
            flex-grow: 0;
        }
    }

    @media #{$mq-medium-screen} {
        .bar {
            height: $header-height;
            padding: 10px 0;
        }

        .link {
            padding-right: 20px;
            padding-left: 10px;
        }

        .logo {
            height: $header-height - 20px;
        }

        &.sticky {
            height: $header-height;

            .bar {
                height: $header-sticky-height;
            }

            .logo {
                height: $header-sticky-height - 10px;
            }
        }
    }
}
