@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    display: flex;
    flex-direction: column;
    user-select: none;
}

.top {
    position: relative;
    margin: 5px 0 5px 10px;

    .bar {
        position: relative;
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        opacity: 1;
        transition: opacity 0.1s ease-out;
    }

    .logoLink {
        display: block;
        margin-right: auto;
    }

    .logo {
        display: block;
        width: 87px;
        height: 30px;
        margin-right: auto;
    }

    .burger {
        display: block;
    }

    .searchButton {
        display: block;
        width: 25px;
        height: auto;
        padding: 0 0 1px;
        margin-right: 15px;
    }

    .searchOpened & {
        .bar {
            opacity: 0;
        }
    }
}

.fadeLeft,
.fadeRight {
    position: absolute;
    top: 0;
    display: block;
    width: 10px;
    height: 60px;
    opacity: 1;
    pointer-events: none;
}

.fadeLeft {
    z-index: 1;
    left: 0;
}

.fadeRight {
    right: 0;
}

.popular {
    position: relative;
}

.search {
    position: relative;
    width: 100%;
    background-color: rgba($color-yellow, 0.15);
    text-align: right;

    .form {
        height: 40px;
        background-color: inherit;
        color: inherit;

        .input {
            font-size: $font-size-normal-large;

            &::placeholder {
                color: inherit;
            }
        }
    }
}

.popular {
    padding: 5px 0;
    border-width: 1px 0;
    border-style: dotted;

    .list {
        @extend %hide-scrollbars;

        overflow: hidden;
        overflow-x: auto;

        &::before {
            display: none;
        }
    }

    .item {
        min-width: 120px;

        &:last-child {
            &::after {
                display: none;
            }
        }
    }

    @media #{$mq-medium-screen} {
        display: none;
    }
}

.menus {
    @extend %hide-scrollbars;
    @extend %font-smoothing;

    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;

    .item {
        display: block;
        padding-bottom: 10px;
        text-align: center;

        &:last-child {
            padding-bottom: 0;
        }
    }

    .link {
        display: inline-block;
        border-bottom: 2px solid;
        border-bottom-color: inherit;
        font-family: $font-title;
        font-weight: 700;
        letter-spacing: 0.025em;
        text-transform: uppercase;
        transition: color $default-easing-time $default-easing-function;

        &:hover {
            // color: rgba(#fffc00, 0.7);
        }
    }

    .subMenu {
        display: block;
        padding: 0;
    }
}

%section-title {
    @extend %brand-text-style;

    padding-bottom: 10px;
    border-bottom: 0;
    color: inherit;
    font-size: $font-size-small;
    font-weight: 700;
}

.mainMenu {
    width: 100%;
    flex-grow: 1;
    padding: 20px 10px 40px;
    font-size: $font-size-medium-large;

    .item {
        &.hasSubMenu {
            padding-bottom: 30px;

            > .link {
                @extend %section-title;
            }
        }
    }
}

.linksMenu {
    padding: 20px 15px 25px;
    border-top: 1px solid;
    font-size: $font-size-medium-small;

    .columns {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
    }

    .menu {
        display: block;
        width: 50%;
        padding: 0 10px;

        &:last-child {
            border-left: 1px dotted;
            line-height: 1.2;

            .link {
                @extend %brand-text-style;

                border-bottom: 0;
                font-size: $font-size-smaller;
            }
        }
    }

    .label {
        @extend %section-title;
    }

    .item {
        padding-bottom: 5px;

        &:last-child {
            padding-bottom: 0;
        }
    }
}
