@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    display: block;
    width: 100%;

    .box {
        position: relative;
        padding: 20px 0;
        border-radius: 0;
        box-shadow: 0 -2px 20px 0 rgba($color-black, 0.5);
    }

    .inner {
        @extend %page-inner;

        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .closeButton {
        position: absolute;
        z-index: 4;
        bottom: 100%;
        left: 50%;
        width: 36px;
        height: 36px;
        transform: translate(-50%, 0);

        &.arrow {
            height: 30px;
            border-top-left-radius: 50%;
            border-top-right-radius: 50%;
            color: $color-white;
            transform: translate(-50%, -50%);
        }

        &.cross {
            display: none;
        }
    }

    &.quatre95 {
        .arrow {
            background-color: #f04e3e;
        }
    }

    &.dehors {
        .arrow {
            background: #37fdb1;
            color: #222;
        }
    }

    &.dehorsGradient {
        .arrow {
            background: rgb(246 203 69);
        }
    }

    &.urbania {
        .arrow {
            background-color: #8a66fc;
        }
    }

    &.parents {
        .arrow {
            background: #37fdb1;
            color: #222;
        }
    }

    .icon {
        position: absolute;
        z-index: 1;
        top: 0%;
        left: 85%;
        display: block;
        width: 25%;
        height: 100%;
        transform: translate(-55%, -55%) rotate(-8deg);
    }

    .content {
        position: relative;
        z-index: 2;
        width: 100%;
    }

    .text {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
    }

    .title {
        width: 80%;
        max-width: none;
        font-size: 1.7em;
    }

    .description {
        // display: none;
        width: 90%;
        margin: 0.2em 0;
        word-break: keep-all;
    }

    .textContent {
        width: 100%;
        flex-grow: 1;
    }

    .action {
        position: relative;
        z-index: 3;
    }

    @media #{$mq-very-small-screen} {
        .title {
            width: 70%;
        }

        .description {
            width: 70%;
        }
    }

    @media #{$mq-small-screen} {
        .closeButton {
            right: auto;
            bottom: 100%;
            left: 100%;
            transform: translate(-110%, 0);

            &.arrow {
                display: none;
            }

            &.cross {
                display: block;
            }
        }

        .icon {
            top: 50%;
            left: 10%;
            width: 35%;
            height: 160%;
            transform: translate(-55%, -55%) rotate(-8deg);
        }

        .content {
            width: 70%;
            margin-left: auto;
        }

        .title {
            width: 100%;
        }

        .description {
            width: 90%;

            // display: none;
        }

        .text {
            width: 100%;

            // flex-direction: row;
            // align-items: center;
            flex-direction: column;
            align-items: flex-start;
        }

        .title {
            margin-right: 0.5em;
            font-size: 2em;

            // min-width: 8em;
        }

        .action {
            max-width: 400px;
        }
    }

    @media #{$mq-medium-screen} {
        .text {
            width: 50%;
            flex-direction: column;
            align-items: flex-start;
        }

        .action {
            width: 100%;
            min-width: 300px;
        }

        .content {
            display: flex;
            width: 75%;
            flex-direction: row;
            align-items: center;
        }

        .title {
            font-size: 2.5em;
        }
    }
}
