@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    display: block;
    width: 100%;
    padding: 0 10px;

    .box {
        position: relative;
        padding: 20px 10px 10px;
    }

    .closeButton {
        position: absolute;
        z-index: 4;
        top: -15px;
        right: -5px;
        width: 35px;
        height: 35px;
    }

    .icon {
        position: absolute;
        z-index: 1;
        top: 20%;
        left: 15%;
        display: block;
        width: 30%;
        height: 70%;
        transform: translate(-55%, -30%) rotate(-8deg);
    }

    .content {
        position: relative;
        z-index: 2;
        display: flex;
        width: 100%;
        flex-direction: column;
        margin-left: auto;
    }

    .text {
        width: 67%;
        margin-left: auto;
    }

    .action {
        position: relative;
        z-index: 3;
        margin-top: 10px;
        text-align: center;
    }

    .evil
    {
        display: flex;
        justify-content: center;
        padding-top: 8px;
    }


    @media #{$mq-small-screen} {
        max-width: 425px;
    }

    @media #{$mq-medium-screen} {
        max-width: 475px;

        .box {
            position: relative;
            padding: 20px;
        }

        .closeButton {
            top: -10px;
            right: -10px;
        }

        .icon {
            top: 35%;
            transform: translate(-55%, -50%) rotate(-8deg);
        }

        .text {
            width: 65%;
        }

        .action {
            width: 100%;
            margin-top: 20px;
            margin-left: auto;
            text-align: left;
        }

        &.withButton {
            .action {
                width: 65%;
            }
        }
    }
}
