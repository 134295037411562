@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    position: relative;
    max-width: 100vw;
    min-height: 100vh;

    .bar {
        background: #fff;
    }

    .loading {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 2;
    }

    .inner {
        transition: opacity 0.2s ease-out;
    }
}
