@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    position: relative;

    .spacer {
        display: block;
        height: 1em;
    }

    .subMenu {
        display: none;
        padding-bottom: 1em;

        &.opened {
            display: block;
        }
    }
}
