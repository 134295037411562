@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    position: relative;
    max-width: 100vw;
    min-height: 100vh;

    .inner {
        transition: opacity 0.2s ease-out;
    }
}
