@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';
@import '../commons/animations';

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-bottom: 1px solid;
    background-color: #011591;
    background-image: url('../../img/lair-daller.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 98%;

    .inner {
        position: relative;
        display: flex;
        width: 100%;
        max-width: 1280px;
        height: auto;
        flex-direction: column;
        align-items: flex-end;
        margin: 0 auto;
    }

    .video {
        width: 100%;
        margin-bottom: 10px;
    }

    .button {
        display: flex;
        width: fit-content;
        height: 40px;
        align-items: center;
        padding: 2px;
        background-color: $color-white;
        border-radius: 2px;
        font-family: $font-slab;
        transition: transform 0.2s ease;

        &:hover {
            transform: translateX(2px);
        }
    }

    .buttonLogo {
        display: inline-block;
        width: auto;
        width: 40px;
        height: auto;
        padding-right: 5px;
    }
}
