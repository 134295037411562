@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    position: relative;

    .viewMapButton {
        position: fixed;
        z-index: 3;
        right: 10px;
        bottom: 10px;
        transform: translate(300px, 0);
        transition: transform 0.2s ease-in-out;

        &.visible {
            transform: translate(0, 0);
        }
    }

    .more {
        padding: 40px 0;
        text-align: center;
    }

    &.isFullscreen {
        @extend %fullscreen;

        .article {
            @extend %fullscreen;
        }
    }

    @media #{$mq-medium-screen} {
        .viewMapButton {
            right: 20px;
            bottom: 20px;
        }
    }
}
