@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

$logo-small-height: 1.5rem;
$logo-height: 2rem;

.container {
    position: relative;

    .bar {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        border-bottom: solid 1px $color-black;
    }

    .inner {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: flex-end;
    }

    .link {
        display: block;
    }

    .logo {
        display: block;
        width: auto;
        height: $logo-small-height;
        margin: 0.5rem;
    }

    .left {
        align-self: center;
    }

    .center {
        flex-grow: 1;
    }

    .menu {
        width: 100%;
    }

    .items {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
    }

    .item {
        padding: 0 0.75rem;
        margin: 0;
        font-size: 0.8rem;

        .link {
            padding: 0.75rem 0;
            font-family: $font-slab-tight;
            font-weight: 500;
            text-transform: uppercase;
        }

        &.active {
            .link {
                border-bottom: solid 2px #000;
            }
        }

        &.logout {
            margin-left: auto;
            font-size: 0.75em;
        }
    }

    &.darkTheme {
        .bar {
            background-color: $color-black;
        }
    }

    &.sticky {
        .bar {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            box-shadow: 0 1px 4px rgba($color-black, 0.2);
            z-index: 2;
        }
    }

    @media #{$mq-small-screen} {
        .left {
            flex-grow: 0;
        }
    }

    @media #{$mq-medium-screen} {
        .logo {
            height: $logo-height;
        }

        .item {
            padding: 0 1rem;
            margin: 0;
            font-size: 1rem;

            .link {
                padding: 0.9rem 0;
            }

            &.logout {
                font-size: 0.75rem;
            }
        }
    }
}
