@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    position: fixed;
    z-index: 21;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transform: translateY(300%);
    transition: transform 0.35s ease, opacity 0.35s ease;

    &.opened {
        opacity: 1;
        transform: translateY(0%);
    }

    @media #{$mq-medium-screen} {
        bottom: 10px;
        left: 0;
        max-width: 300px;
        opacity: 0;
        transform: translate(-200%, -50%);

        &.opened {
            opacity: 1;
            transform: translate(0%, -50%);
        }

        &.bottom {
            bottom: 0;
            left: 50%;

            // width: 100%;
            max-width: none;
            opacity: 0;
            transform: translate(-50%, 300%);
            transition: transform 0.35s ease, opacity 0.35s ease;

            &.opened {
                opacity: 1;
                transform: translate(-50%, 0%);
            }
        }
    }
}
