@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    @extend %font-smoothing;

    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px;
    color: inherit;
    transition: opacity 0.1s $default-easing-function 75ms;

    .thumbnail {
        position: relative;
        display: block;
        width: 55px;
        height: 55px;
        margin-right: 12px;
        aspect-ratio: 1/1;
    }

    .thumbnailImg {
        overflow: hidden;
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }

    .textIcon {
        display: block;

        // width: 50%;
        height: 50px;
        margin-right: 10px;
    }

    .play {
        position: absolute;
        z-index: 2;
        top: 50%;
        right: -20px;
        width: 30px;
        height: 30px;
        filter: drop-shadow(0 0 1px rgb(0 0 0 / 0.2));
        transform: translateY(-50%);
    }

    .sponsorIcon {
        display: block;
        width: 38px;
        height: 38px;
    }

    &:hover {
        .thumbnailImg,
        .textIcon {
            opacity: 0.75;
        }
    }
}
