@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    display: block;

    .box {
        position: relative;
        padding: 20px 0;
        border-radius: 0;
    }

    .inner {
        @extend %page-inner;

        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .icon {
        position: absolute;
        z-index: 1;
        top: 30%;
        left: 15%;
        display: block;
        width: 32%;
        height: 70%;
        transform: translate(-55%, -55%) rotate(-8deg);

        &.dehorsMailbox {
            transform: translate(-55%, -55%) rotate(-3deg);
        }
    }

    .micromag {
        position: relative;
        top: -150%;
        left: 30%;
        transform: scale(0.25);
        transform-origin: center;
    }

    .content {
        position: relative;
        z-index: 2;
        width: 100%;
        margin-left: auto;
    }

    .text {
        width: 70%;
        margin-left: auto;
    }

    .action {
        position: relative;
        z-index: 3;
        margin-top: 10px;
        text-align: center;
    }

    .cta {
        @extend %brand-text-style;

        max-width: 14em;
        margin-bottom: 0.5em;
        font-size: $font-size-normal;
        line-height: 1.1;
        text-align: left;
    }

    @media #{$mq-small-screen} {
        .icon {
            top: 50%;
            height: 100%;
        }

        .content {
            width: 70%;
        }

        .text {
            width: 100%;
            margin-left: 0;
        }

        .action {
            text-align: left;
        }

        .field {
            max-width: 350px;
        }
    }

    .evil {
        padding-top: 10px;
    }

    @media #{$mq-medium-screen} {
        .box {
            padding: 20px 0;
        }

        .inner {
            min-height: 200px;
        }

        .content {
            width: 65%;
        }

        .icon {
            top: 50%;
            left: 20%;
            width: 40%;
            height: 130%;

            // transform: translate(-55%, -50%) rotate(-8deg);
        }

        .text {
            width: 100%;
            margin: 0;
        }

        // .text {
        //     display: flex;
        //     flex-direction: row;
        //     align-items: center;
        // }

        .title {
            // width: 35%;
            min-width: 4em;
            margin: 0;
            margin-bottom: 0.2em;
        }

        .field {
            width: 350px;
        }

        .description {
            margin-top: 0;
        }

        // .icon {
        //     transform: translate(7%, -55%) rotate(-8deg);
        // }
    }

    @media #{$mq-large-screen} {
        .text {
            font-size: $font-size-normal-large;
        }

        .content {
            width: 62%;
        }

        .icon {
            width: 38%;
            height: 100%;
        }
    }
}
