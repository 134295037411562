@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    @extend %reset-button;
    display: inline-block;
    padding: 8px 10px 10px;
    background-color: inherit;
    color: inherit;

    .icon {
        display: block;
        width: 100%;
        height: auto;
    }
}

// .container {
//     @extend %reset-button;
//
//     display: inline-block;
//     background-color: inherit;
//     color: inherit;
//
//     .icon {
//         display: block;
//         width: 100%;
//         height: auto;
//     }
// }
