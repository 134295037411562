@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    display: block;
    width: 30px;

    .circle {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        background: #f9e1ca;
        border-radius: 50%;
    }

    .pin {
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        width: 50%;
        height: 50%;
        display: block;
        color: #fef0e0;
        transform: translate(-50%, -50%);
    }

    .image {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        width: 90%;
        height: 90%;
        object-fit: cover;
        border-radius: 50%;
        transform: translate(-50%, -50%);
    }
}
