@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

@mixin banner-before-gradient {
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 50%;
    display: block;
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(
        0deg,
        rgba(0 0 0 / 40%) 20%,
        rgba(0 0 0 / 20%) 30%,
        rgba(0 0 0 / 0%) 40%
    );
    content: '';
    pointer-events: none;
    transition: transform 0.2s ease-in-out;
    transform: translate(-50%, 0);
}

.container {
    z-index: 5;

    &.banner {
        transition: transform 0.2s ease-in-out, width 0.2s ease-in-out, opacity 0.2s ease-in-out;

        &::before {
            @include banner-before-gradient;
        }

        &.formOpened {
            width: 95%;
            transform: translate(2.5%, -60vh);

            &::before {
                display: none;
            }

            @media #{$mq-medium-screen} {
                width: 100%;
                transform: translate(-50%, 0);

                &::before {
                    @include banner-before-gradient;
                }
            }
        }
    }
}

.safe {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0 0 0 / 50%);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-out;

    &.opened {
        opacity: 1;
        pointer-events: inherit;
    }
}

.inner {
    position: relative;
    width: 100%;

    .modal & {
        position: relative;
        width: 95%;
        max-width: 380px;
        max-height: 70%;
        transition: transform 0.2s ease-out;
    }

    .modal.formOpened & {
        transform: translate(0, -10vh);

        @media #{$mq-medium-screen} {
            transform: translate(0, 0);
        }
    }
}

.box {
    display: flex;
    width: 100%;

    .modal & {
        flex-direction: column;
        justify-content: center;
        border-radius: 15px;
    }

    .banner & {
        height: 80px;
        flex-direction: row;
        flex-grow: 1;
        align-items: flex-end;
        transition: height 0.2s ease-out;
    }

    .banner.formOpened & {
        overflow: hidden;
        border-radius: 5px;

        @media #{$mq-medium-screen} {
            overflow: visible;
            border-radius: 0;
        }
    }

    &.withColor {
        background-image: linear-gradient(0deg, rgb(0 0 0 / 25%) 0%, rgb(0 0 0 / 0%) 100%);
    }

    &.withInfolettreBackground {
        background-image: url('../../img/newsletter-icons/bg-q95.svg');
        background-repeat: no-repeat;

        .modal & {
            background-image: url('../../img/newsletter-icons/bg-q95-modal.svg');
            background-position: center center;
        }
    }

    &.withSeasonBackground {
        background-image: url('../../img/dehors/fall.png');
        background-position: center;
        background-repeat: repeat;
        background-size: cover;

        &.summer {
            background-image: url('../../img/dehors/summer.png');
        }

        &.fall {
            background-image: url('../../img/dehors/fall.png');
        }

        &.spring {
            background-image: url('../../img/dehors/spring.png');
        }

        &.winter {
            background-image: url('../../img/dehors/winter.png');
        }
    }
}

.wrapper {
    position: relative;
    overflow: hidden;

    .banner & {
        width: 100%;
        height: 100%;
        padding-left: 5px;
        margin-left: -5px;
    }

    .modal & {
        display: flex;
        min-height: 250px;
        flex-direction: column;
        justify-content: center;
        transition: min-height 0.2s ease-out;
    }

    .modal.formOpened & {
        min-height: 100px;
    }
}

.icon {
    display: block;
    width: 100%;
    width: 90px;
    height: auto;
    margin-right: 0;

    &.square {
        width: 80px;
        margin: 10px;
        margin-top: 0;
    }

    &.margin {
        margin: 0 5px;
    }

    .banner & {
        transition: margin 0.2s ease-in-out;
    }

    .modal & {
        position: absolute;
        bottom: 100%;
        left: 20px;
        opacity: 1;
        transform: translate(0, 60px);
        transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    }

    .banner.formOpened & {
        margin-right: 10px;
        margin-left: -90px;

        &.square {
            margin-left: -80px;
        }
    }

    .modal.formOpened & {
        opacity: 0;
        transform: translate(0, 0);
    }

    @media #{$mq-medium-screen} {
        width: 110px;

        &.square {
            width: 80px;
        }

        .banner.formOpened & {
            margin-left: 0;

            &.square {
                margin-left: 10px;
            }

            &.margin {
                margin-left: 5px;
            }
        }

        .modal.formOpened & {
            opacity: 1;
            transform: translate(0, 60px);
        }
    }
}

.label {
    @extend %reset-button;

    display: flex;
    width: 100%;
    max-width: 800px;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px 10px 10px 0;
    text-align: left;

    .modal & {
        padding: 20px;
        padding-top: 70px;
        transition: padding-top 0.2s ease-out;
    }

    .modal.formOpened & {
        padding-top: 20px;

        @media #{$mq-medium-screen} {
            padding-top: 70px;
        }
    }
}

.title,
.thankYou {
    padding-right: 40px;
    margin-bottom: 4px;
    font-family: $font-title;
    font-size: $font-size-medium;
    line-height: 0.9;
    text-transform: uppercase;

    .modal & {
        margin-bottom: 10px;
        font-size: $font-size-large;
    }

    @media #{$mq-medium-screen} {
        font-size: $font-size-large;
    }
}

.thankYou {
    font-size: $font-size-medium;

    .modal & {
        font-size: $font-size-medium;
    }
}

.callToAction {
    display: inline-block;
    padding: 0 5px;
    background: $color-yellow;
    font-family: $font-slab-tight;
    font-size: $font-size-smaller;
    line-height: 1.1;
    text-align: left;
    white-space: nowrap;
    color: $color-black;

    .modal & {
        font-size: $font-size-normal;
    }

    @media #{$mq-medium-screen} {
        font-size: $font-size-small;

        .modal & {
            font-size: $font-size-normal;
        }
    }
}

.closeButton {
    position: absolute;
    z-index: 10;
    top: -20px;
    right: 0;
    width: 50px;
    height: 50px;
    padding: 10px;
    transition: transform 0.2s ease-in-out;

    .formOpened.banner & {
        transform: translate(0, -10px);
    }

    @media #{$mq-medium-screen} {
        .formOpened.banner & {
            transform: translate(0, 0);
        }
    }
}

.description {
    margin-bottom: 5px;
    font-family: $font-slab;
    font-size: $font-size-normal;
}

.form {
    width: 100%;
    border: 1px solid $color-dark-gray;
    background-color: #fff;
    border-radius: 5px;

    .input {
        padding: 13px 13px 10px 13px;
        border-bottom: 0;
        font-size: $font-size-normal;
        color: $color-black;

        &::placeholder {
            font-family: $font-slab;
            font-size: $font-size-normal;
            color: rgba($color-black, 0.5);
            font-weight: 700;
            text-transform: none;
        }

        &:focus {
            z-index: 2;
        }
    }

    .button {
        padding: 13px 13px 10px 13px;
        border-bottom: 0;
        border-left: 1px solid $color-dark-gray;
        background-color: $color-yellow;
        border-radius: 0 4px 4px 0;
        color: #000;
        transition: background-color 0.2s ease-out;

        &:focus {
            z-index: 2;
        }

        // &:hover {
        //     background-color: $color-q95-blue;
        // }

        svg {
            width: 25px;
            height: auto;
        }
    }

    @media #{$mq-medium-screen} {
        max-width: 500px;
    }
}

/* stylelint-disable selector-class-pattern */
.enter {
    z-index: 1;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    transform: translate(-100%, 0);
    opacity: 0;
}

.enterActive {
    transform: translate(0, 0);
    opacity: 1;
}

.exit {
    position: absolute;
    top: 0;
    z-index: 2;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    transform: translate(0, 0);
    opacity: 1;
}

.exitActive {
    transform: translate(100%, 0);
    opacity: 0;
}
/* stylelint-enable selector-class-pattern */
