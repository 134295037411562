@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    position: relative;
    max-width: 100vw;
    min-height: 100vh;

    .loading {
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
    }

    .inner {
        transition: opacity 0.2s ease-out;
    }

    .header {
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
    }

    .mainMenu {
        position: fixed;
        z-index: $zindex-main-menu;
        top: 0;
        right: 0;
        width: 300px;
        height: 100%;
        transform: translate3d(300px, 0, 0);
        transition: transform $default-easing-time $default-easing-function;

        &.opened {
            transform: translate3d(0, 0, 0);
        }
    }

    .mainMenuBlock {
        position: fixed;
        z-index: $zindex-main-menu-block;
        display: none;
        visibility: hidden;

        &.opened {
            display: block;
            visibility: visible;
        }
    }

    @media #{$mq-medium-screen} {
        .mainMenu {
            width: 320px;
            transform: translate3d(320px, 0, 0);
        }
    }
}
