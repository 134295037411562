@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    position: relative;
    overflow: hidden;
    max-width: 100vw;
    min-height: 100vh;

    .inner {
        transition: opacity 0.2s ease-out;
    }

    .content {
        &.minHeightViewport {
            min-height: 100vh;
        }
    }

    .loading {
        position: fixed;
        z-index: 5;
        top: 0;
        left: 0;
        width: 100%;
    }

    .hidden {
        display: none;
    }

    .header {
        z-index: $zindex-header;
        color: $color-black;
    }

    &.scrollDisabled {
        overflow: hidden;
        height: 100vh;
    }

    &.isFullscreen {
        display: flex;
        flex-direction: column;

        .inner {
            position: relative;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }

        .content {
            height: 100%;
            flex-grow: 1;
        }
    }

    // theme
    &.card {
        background-color: $color-white;
    }
}
