@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    // padding-top: 60px;
    // background-color: $color-ad-background;
    color: $color-black;

    .closeButton {
        @extend %reset-button;
        position: absolute;
        top: 10px;
        right: 10px;
        width: 40px;
        height: 40px;
        padding: 5px;
        color: $color-black;
    }

    .close {
        transform: scale(1.4);
    }

    .asset {
        max-width: 100%;
        margin-top: 60px;
    }

    .call {
        position: relative;
        right: 0;
        bottom: 0;
        width: 100%;
    }

    .arrow {
        transform: rotate(90deg);
        transform-origin: center;
    }

    .next {
        @extend %reset-button;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 5px;

        .call,
        .siteName,
        .arrow {
            display: block;
        }

        .call {
            font-family: $font-other;
            text-transform: uppercase;
        }

        .siteName {
            @extend %text-link;
            font-family: $font-title;
            font-size: $font-size-medium;
            text-transform: uppercase;
        }
    }

    .pixels {
        width: 1px;
        height: 1px;
        opacity: 0;
    }
}
