@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    max-width: 100vw;

    .inner {
        padding-bottom: 40px;
    }

    &.newReaderDesign {
        .header {
            &.isReader {
                padding-bottom: 20px;
            }
        }
    }

    .metadataContainer {
        .inner {
            @extend %article-inner;

            padding-bottom: 0;
        }
    }

    .topAd {
        margin-top: 10px;

        &.empty {
            margin-top: 0;
        }
    }

    .metadata {
        padding: 1rem 0;
    }

    .sponsors {
        padding: 1rem 0 2rem;
    }

    .newsletterBlock {
        margin-bottom: 20px;
    }

    .endBlock {
        margin-top: 30px;
    }

    .isWhite {
        background: $color-white;
    }

    &.withoutSidebars {

    }

    &.isArticle {
        min-height: 100vh;
    }

    &.isFullscreen {
        .header {
            @extend %fullscreen;
        }
    }

    &.themeQuatre95Account {
        background-color: #dee4ff;
        background-image: url('../../img/quatre95/quatre95_tile.png');
        background-position: top center;
        background-repeat: repeat;
        color: #5e79ff;
    }

    &.themeSepaqMontmorency {
        background-image: url('../../img/sepaq/montmorency-tile2.png');
        background-position: top center;
        background-repeat: repeat;
        background-size: 200px 200px;
    }

    &.themeBixiSoiree {
        background: rgb(2 0 36);
        background: linear-gradient(
            180deg,
            #efead6 0%,
            #e28942 22%,
            #a2b1cb 32%,
            #091c2b 73%,
            #091c2b 100%
        );
        background-repeat: no-repeat;
    }

    &.themeCascadesRouleau {
        padding-top: 40px;
        padding-left: 23.5%;
        background-color: $color-cascades-bg;
        color: $color-cascades-text !important;

        & > * {
            position: relative;
            width: 100%;
            padding-top: 60px;
            border-right: 2px solid $color-cascades-bg;
            border-left: 3px solid #fcfcfc;
            margin-right: auto;
            margin-left: auto;
            background-color: #fcfcfc;

            &::before {
                position: absolute;
                width: 100%;
                height: 100%;
                background-image: url('../../img/cascades/emboss.png'),
                    url('../../img/cascades/tp-texture.png');
                background-position:
                    top left,
                    top left;
                background-repeat: repeat;
                background-size: 30%, 60%;
                content: '';
                inset: 0;
                opacity: 0.1;
            }
        }

        .inner {
            padding-top: 0;
        }

        .metadataContainer {
            padding-top: 0;
        }

        .sponsors {
            & > div:first-child {
                color: $color-cascades-text;
                font-weight: normal;
                text-transform: none;
            }

            img {
                max-height: 3em;
            }
        }

        p:global(.footnotes) {
            font-size: 0.75rem;

            a {
                white-space: wrap;
                word-wrap: break-word;
            }
        }
    }

    @media #{$mq-small-screen} {
        &.themeCascadesRouleau {
            padding-left: 24%;
        }
    }

    @media #{$mq-medium-screen} {
        .metadata {
            padding: 2rem 0;
            font-size: $font-size-medium-small;
        }

        &.newReaderDesign {
            .header {
                &.isReader {
                    padding-bottom: 40px;
                }
            }
        }
    }

    @media #{$mq-large-screen} {
        &.themeCascadesRouleau {
            padding-top: 80px;
            padding-left: 0;

            .inner {
                padding-bottom: 0;
                margin-bottom: 0;
            }

            & > * {
                max-width: 700px;
                border-right: 10px solid white;
                border-left: 10px solid white;
            }
        }
    }
}
