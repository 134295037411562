@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    position: relative;
    width: 100%;
    max-width: none;
    overflow: hidden;
    color: $color-white;
    font-family: $font-title;
    font-size: 500px;
    line-height: 0.65;
    text-transform: uppercase;
    cursor: default;

    .fade {
        @extend %fullscreen;
        z-index: 2;
        opacity: 0.3;
        background: $color-black;
    }

    .background {
        @extend %fullscreen;
        z-index: 1;
    }

    .text {
        position: absolute;
        z-index: 3;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -40%);
        opacity: 0.06;
        font-size: 1.3em;
        line-height: inherit;
        white-space: nowrap;
    }
}
