@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    width: 100%;
    height: 100%;
    font-family: $font-text;
    font-weight: normal;

    .form {
        display: flex;
        position: relative;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .input {
        @extend %reset-input;

        display: block;
        flex-grow: 1;
        width: 100%;
        height: 100%;
        padding: 0 15px;

        &:focus,
        &:active {
            background-color: inherit;
            color: inherit;
        }

        .icon {
            display: block;
        }
    }

    .submitButton,
    .closeButton {
        @extend %reset-button;
        display: block;
        width: 30px;
        height: auto;
        margin-right: 10px;
    }

    .closeButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 30px;
        margin-right: 0;
        padding: 6px 10px;
    }
}
