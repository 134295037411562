@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    .title {
        margin-bottom: 1em;
    }

    .card {
        padding: 20px 0;
        font-size: $font-size-small;

        .content {
            display: flex;
            flex-direction: column;
        }

        .label {
            flex-grow: 1;
            margin-right: 10px;
        }
    }

    .item {
        border-bottom: solid 1px #000;
    }

    &.withMaxWidth {
        .inner {
            @extend %article-inner;
        }
    }

    @media #{$mq-medium-screen} {
        .card {
            font-size: $font-size-normal;

            .content {
                flex-direction: row;
                align-items: center;
            }
        }
    }
}
