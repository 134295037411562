@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    @extend %brand-text-style;
    position: relative;

    .inner {
        @extend %page-inner;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .list {
        margin-bottom: 0.5rem;
        padding-bottom: 1em;
        font-size: $font-size-smaller;
        text-align: left;

        &:first-child {
            font-size: $font-size-normal;
        }

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }

    .sponsor {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
    }

    .brand {
        display: none;
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;

        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 40px;
        height: 100%;

        .icon {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    @media #{$mq-medium-screen} {
        .inner {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        .lists {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            margin: 0 auto;
        }

        .list {
            min-width: 320px;
            margin-bottom: 0;
            padding-bottom: 0;
        }

        .sponsor {
            margin-top: 30px;
        }
    }

    @media #{$mq-large-screen} {
        .inner {
            padding-top: 40px;
            padding-bottom: 40px;
        }

        .brand {
            display: flex;
            visibility: visible;
        }

        .sponsor {
            margin-top: 40px;
        }
    }

    @media #{$mq-larger-screen} {
        .brand {
            width: 60px;

            svg {
                width: 60px;
            }
        }
    }
}

$menu-gutter: 1.2rem;

%link-with-icon {
    display: block;
    line-height: inherit;

    &::before {
        content: '—';
        display: inline-block;
        width: $menu-gutter;
        color: inherit;
        font-size: 1rem;
        line-height: 0.9em;
        text-align: left;
    }
}

.menu {
    @extend %font-smoothing;

    .link {
        @extend %link-hover;
    }

    .item {
        margin-bottom: 0.5rem;
        line-height: 1.1;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .label,
    .link {
        @extend %inherit-text-style;
    }
}

.hasSubMenu {
    .link,
    .label {
        @extend %link-with-icon;
    }

    > .link,
    > .label {
        &::before {
            content: '+';
        }
    }

    > .label,
    &.opened > .link {
        &::before {
            content: '-';
            float: left;
            color: inherit;
        }
    }

    &:last-child {
        > .label,
        &.opened > .link {
            &::before {
                @extend %link-with-icon;
                content: '+';
                padding: 0;
                float: none;
            }
        }

        .item {
            &:last-child {
                .link {
                    &::before {
                        content: '—';
                    }
                }
            }
        }

        .subMenu {
            padding-bottom: 0;
        }
    }
}

.subMenu {
    padding-top: 0.5rem;
    padding-left: $menu-gutter;
    font-size: 0.85em;

    .link {
        &::before {
            content: '—';
        }
    }

    .item {
        margin-bottom: 0.4rem;

        &:last-child {
            margin-bottom: 0;

            .link {
                &::before {
                    content: '->';
                }
            }
        }
    }
}
