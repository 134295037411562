/**
 * Colors
 */
$color-white: #fff;
$color-yellow: #fffc00;
$color-red: #c00;
$color-hydro-pink: #ffd4f1;
$color-hydro-blue: #000085;
$color-hydro-green: #3ee89a;
$color-q95-pink-gray: #edcad1;
$color-q95-pink: #f7b7c1;
$color-q95-blue: #607dfc;
$color-q95-red: #f04e3e;
$color-q95-yellow: #fee444;
$color-dehors-white: #fff0e0;
$color-dehors-green: #0c9;
$color-dehors-darkblue: #0b002b;
$color-france-blue: #006;
$color-france-yellow: #ff0;
$color-france-light-blue: #00f;
$color-ligne-directe-purple: #cf07e5;
$color-ligne-directe-blue: #4441ff;
$color-light-black: #231f20;
$color-black: #000;
$color-dark-gray: #333;
$color-dark-gray-micromag: #222;
$color-gray: #999;
$color-light-gray: #f2f2f2;
$color-background: $color-white;
$color-text: $color-black;
$color-ad-text: #b3b3b3;
$color-ad-background: #f2f2f2;
$color-micromag-fushia: #ea49f7;
$color-micromag-purple: #ab23cc;
$color-subscription-green: #00ff89;
$color-mollo-purple: #a0f;
$color-mollo-orange: #fc0;
$color-mollo-smoothie: #fdc;
$color-mollo-white: #fff8ee;
$color-cascades-bg: #81bd41;
$color-cascades-text: #2b5844;

/**
 * Fonts
 */
$font-system: arial, sans-serif;
$font-text: 'Sina Nova', serif;
$font-title: 'Garage Gothic', sans-serif;
$font-other: 'Agrandir', sans-serif;
$font-slab-variable: 'Agrandir Variable', sans-serif; // NOT IMPORTED!
$font-slab: 'Agrandir', sans-serif;
$font-slab-tight: 'AgrandirTight', sans-serif;
$font-slab-narrow: 'AgrandirNarrow', sans-serif;
$font-slab-grand: 'AgrandirGrand', sans-serif;
$font-form: 'Roboto', sans-serif;
$font-quatre95: 'Maax', sans-serif;
$font-university: 'Wanted', serif;
$font-dehors: 'Avant Garde', serif;
$font-live: 'Hochelaga', 'Brandon Grotesque', sans-serif;
$font-brandon: 'Brandon Grotesque', sans-serif;
$font-size-smallest: 10px;
$font-size-smaller: 12px;
$font-size-small: 14px;
$font-size-normal: 16px;
$font-size-normal-large: 18px;
$font-size-medium-small: 20px;
$font-size-medium: 24px;
$font-size-medium-large: 28px;
$font-size-medium-larger: 30px;
$font-size-large-small: 34px;
$font-size-large: 40px;
$font-size-larger: 48px;
$font-size-largest: 60px;

/**
 * z-index
 */
$zindex-main-menu: 6;
$zindex-main-menu-block: 4;
$zindex-header: 3;

/**
 * Screen size
 */
$max-page-width: 1040px;
$max-article-width: 580px;
$very-small-screen: 375px;
$small-screen: 500px;
$medium-screen: 768px;
$large-screen: 1100px;
$larger-screen: 728px + 30px + (2 * 320px); // sidebar-level 1398px
$very-large-screen: 1600px;

/**
 * Media queries
 */
$mq-very-small-screen: 'only screen and (min-width: #{$very-small-screen})';
$mq-small-screen: 'only screen and (min-width: #{$small-screen})';
$mq-medium-screen: 'only screen and (min-width: #{$medium-screen})';
$mq-large-screen: 'only screen and (min-width: #{$large-screen})';
$mq-larger-screen: 'only screen and (min-width: #{$larger-screen})';
$mq-very-large-screen: 'only screen and (min-width: #{$very-large-screen})';
$mq-high-screen: 'only screen and (min-width: #{$large-screen}) and (min-height: #{$large-screen})';

/**
 * Animations
 */
$default-easing-time: 0.3s;
$default-easing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-softy: cubic-bezier(0.7, 0, 0.3, 1);
$ease-boom: cubic-bezier(0.2, 2.27, 0.2, 0.78);
$ease-whip: cubic-bezier(0.96, 1.72, 0.01, 0.9);
