@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 10px;

    .logo {
        @extend %link-hover;
        display: block;
        padding: 10px;
    }

    .site {
        margin: 10px 0;
    }

    &.hidden {
        display: none;
        visibility: hidden;
    }

    @media screen and (min-width: 570px) {
        flex-direction: row;
        padding: 20px;

        .site {
            margin: 0 15px;
        }
    }
}
