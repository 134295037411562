@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.inner {
    position: relative;
    width: 95%;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px 10px 40px;
    background: $color-white;
    border-radius: 10px;


    @media #{$mq-medium-screen} {
        margin: auto;
        padding: 20px 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
}

.label {
    display: block;
    margin-bottom: 20px;
    font-family: $font-slab;
    font-size: $font-size-normal;
    font-weight: bold;
}

.closeButton {
    position: absolute;
    top: -20px;
    right: 0;
    width: 50px;
    height: 50px;
    padding: 10px;
}

.form {
    border: 1px solid $color-dark-gray;
    background-color: #fff;
    border-radius: 5px;

    .input {
        padding: 10px;
        border-bottom: 0;
        font-size: $font-size-normal;

        &::placeholder {
            font-family: $font-slab;
            font-size: $font-size-normal;
            font-weight: 700;
            text-transform: none;
        }
    }

    .button {
        padding: 13px 13px 10px;
        border-bottom: 0;
        border-left: 1px solid $color-dark-gray;
        background-color: $color-q95-yellow;
        border-radius: 0 4px 4px 0;
        color: #000;
        transition: background-color 0.2s ease-out;

        &:hover {
            background-color: $color-q95-blue;
        }

        svg {
            width: 25px;
            height: auto;
        }
    }
}
