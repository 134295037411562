@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    .inner {
        @extend %page-inner;

        display: flex;
        flex-direction: row;
        align-items: center;

        padding-top: 10px;
        padding-bottom: 10px;
    }

    .icon {
        width: 90px;
        height: 90px;
    }

    .textBox {
        max-width: 200px;
        margin-left: 15px;
    }

    .title {
        @extend %text-newsletter-title;

        font-size: $font-size-medium-small;
    }

    .description {
        @extend %text-body;

        margin-top: 0.5em;
        font-size: $font-size-smaller;

        a {
            color: $color-text;
            font-weight: 500;
        }
    }

    @media #{$mq-small-screen} {
        .inner {
            padding-top: 20px;
            padding-bottom: 20px;
        }

        .icon {
            width: 130px;
            height: 130px;
        }

        .textBox {
            max-width: 340px;
            margin-left: 15px;
        }

        .title {
            font-size: $font-size-medium-large;
        }

        .description {
            font-size: $font-size-normal;
        }
    }

    @media #{$mq-medium-screen} {
        .inner {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        .icon {
            width: 160px;
            height: 160px;
        }

        .textBox {
            max-width: 400px;
            margin-left: 25px;
        }

        .title {
            font-size: $font-size-large-small;
        }
    }
}
