.container {
    animation: gradient-scroll 1s linear infinite;

    &:hover {
        animation: gradient-scroll 1s linear infinite;
    }

    @keyframes gradient-scroll {
        0% {
            fill: #fffc00;
        }

        50% {
            fill: #f6c;
        }

        100% {
            fill: #f6c;
        }
    }
}
