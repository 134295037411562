@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin: 0 -3px;
    color: $color-white;
    white-space: nowrap;

    .with {
        display: block;
        margin: 0 10px;
    }

    .link {
        display: block;
    }

    .logo {
        display: block;
        width: auto;
        height: 1rem;
    }

    .sponsor {
        transform: scale(1.25);
        transform-origin: 0 50%;
    }

    @media #{$mq-large-screen} {
        .with {
            margin: 0 10px;
        }
    }
}
