@import '../commons/variables';
@import '../commons/placeholders';

.container {
    @extend %reset-button;

    position: relative;
    display: flex;
    height: 40px;
    flex-direction: row;
    align-items: center;
    padding: 0 40px 0 20px;
    background: #fff0e0;
    border-radius: 25px;
    box-shadow: 2px 2px 10px rgba($color-black, 0.2);
    font-family: $font-dehors;
    font-size: $font-size-smaller;
    font-weight: 500;
    line-height: 1.2;

    .label {
        max-width: 11em;
        padding-right: 1em;
    }

    .icon {
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        min-width: 40px;
    }

    .circle {
        box-shadow: -2px 0 4px rgba($color-black, 0.2);
    }

    @media #{$mq-medium-screen} {
        height: 50px;
        padding: 0 40px 0 20px;
        font-size: $font-size-small;

        .icon {
            width: 50px;
            min-width: 50px;
        }
    }
}
