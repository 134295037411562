@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    padding: 15px 0 20px;

    .inner {
        @extend %page-inner;
        position: relative;
    }

    .title {
        @extend %font-smoothing;

        font-family: $font-title;
        font-size: $font-size-medium-small;
        font-weight: normal;
        line-height: 1;
        text-transform: uppercase;
    }

    .button {
        @extend %reset-button;
        @extend %brand-text-style;

        display: flex;
        flex-direction: row;
        align-items: center;

        width: 100%;
        margin-top: 5px;
        padding: 5px 15px;

        border-top-width: 2px;
        border-bottom-width: 4px;
        border-top-style: solid;
        border-bottom-style: solid;

        font-size: $font-size-small;

        .label {
            flex-grow: 1;
            margin: 0 15px;
        }

        .caret {
            transform: rotate(0);
            transition: transform 0.2s ease-out;

            &.opened {
                transform: rotate(180deg);
            }
        }
    }

    .comments {
        display: none;

        &.opened {
            display: block;
        }
    }

    @media #{$mq-medium-screen} {
        .title {
            font-size: $font-size-medium-large;
        }

        .button {
            font-size: $font-size-normal;
        }
    }
}
