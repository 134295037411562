/* stylelint-disable no-descending-specificity */
@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    position: relative;

    .summary {
        @extend %page-inner;

        position: relative;
        max-width: $max-article-width;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .category {
        position: relative;
        margin-bottom: 0.5rem;
        font-size: $font-size-smaller;
    }

    .title {
        @extend %text-heading;

        position: relative;
    }

    .subtitle {
        @extend %text-subtitle;

        position: relative;
        padding-top: 20px;
    }

    .link {
        @extend %link-hover;

        position: relative;
        display: block;
    }

    .metadata {
        margin-top: 20px;
    }

    .locationDetails {
        padding: 50px 0 20px;
    }

    .background {
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        overflow: hidden;
        width: 100%;
        height: calc(100% - 100px);
        background-color: transparent;

        .university {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            font-family: $font-university;
            transform: translate(0, -50%);
        }
    }

    .coloredBackground {
        @extend %fullscreen;
    }

    .bottom {
        .summary {
            max-width: $max-article-width;
        }

        &.withSidePadding {
            .imageContainer {
                padding: 0 10px;
            }
        }
    }

    .top {
        .summary {
            max-width: $max-page-width;
            padding-top: 60px;
            padding-right: 10px;
            padding-left: 10px;
            text-align: center;
        }
    }

    &.hasColoredBackground {
        color: $color-dehors-white;
    }

    &.backgroundIsFull {
        .background {
            display: block;
            height: 100%;
        }
    }

    &.backgroundIsBorder {
        .background {
            position: relative;
            height: 10px;
        }

        .top {
            .summary {
                padding-top: 40px;

                &.isReader {
                    padding-top: 10px;

                    .title {
                        font-size: 32px;
                    }
                }
            }
        }
    }

    &.hideTitle {
        .title {
            display: none;
        }
    }

    &.hideSubtitle {
        .subtitle {
            display: none;
        }
    }

    &.hideCategory {
        .category {
            display: none;
        }
    }

    &.hideTitle.hideCategory {
        .summary {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &.embedOnly {
        .top,
        .embed {
            @extend %fullscreen;
        }
    }

    &.themeQuatre95Account {
        .background {
            background-color: transparent !important;
        }
    }

    &.themeHydroPannes {
        .title {
            color: #ff7800 !important;
        }
    }

    &.themeSepaqMontmorency {
        display: flex;
        flex-direction: column;
        color: #222;

        .top {
            order: 1;
        }

        .bottom {
            order: 0;
        }

        .summary {
            text-align: center;
        }

        .coloredBackground {
            display: none;
        }

        .category {
            margin-bottom: 20px;
        }

        .title {
            font-family: $font-title;
            font-size: $font-size-larger;
            line-height: 0.9em;
            text-align: center;
        }

        .subtitle {
            font-weight: bold;
        }

        .background {
            background-color: transparent !important;
        }

        @media #{$mq-small-screen} {
            .title {
                font-size: $font-size-largest;
            }
        }

        @media #{$mq-medium-screen} {
            .top {
                order: 0;
            }

            .bottom {
                order: 1;
            }

            .category {
                margin-bottom: 30px;
            }

            .subtitle {
                padding-top: 30px;
                font-size: $font-size-medium;
            }

            .title {
                font-size: 96px;
            }
        }
    }

    &.themeCascadesRouleau {
        .background {
            display: none;
            background: none !important;
            visibility: hidden;
        }

        .top {
            padding-top: 70px;
        }

        .image {
            display: none;
        }

        .summary {
            padding-bottom: 0;
        }

        .subtitle {
            max-width: 560px;
            margin: 0 auto;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
        }
    }

    @media #{$mq-medium-screen} {
        .coloredBackground {
            height: 80%;

            &.embedded {
                height: 100%;
            }
        }

        .summary {
            padding-top: 40px;
            padding-bottom: 40px;
        }

        .bottom {
            .summary {
                max-width: $max-page-width;
                padding-right: 10px;
                padding-left: 10px;
                text-align: center;
            }

            &.withSidePadding {
                .imageContainer {
                    padding: 0;
                }
            }
        }

        .category {
            margin-bottom: 1rem;
            font-size: $font-size-small;
        }

        .imageContainer {
            max-width: 700px;
            margin: 0 auto;
        }

        .background {
            display: block;
            background-color: auto;
        }

        &.imageIsFull {
            .imageContainer {
                max-width: 100vw;
                margin: 0 auto;
            }
        }

        &.backgroundIsBorder {
            .top {
                .summary {
                    &.isReader {
                        padding-top: 40px;

                        .title {
                            font-size: 48px;
                        }
                    }
                }
            }
        }

        &.themeCascadesRouleau {
            .title {
                padding-right: 1rem;
                padding-left: 1rem;
            }
        }
    }

    @media #{$mq-large-screen} {
        .imageContainer {
            max-width: 900px;
        }

        &.imageIsFull {
            .imageContainer {
                max-width: 100vw;
                margin: 0 auto;
            }
        }

        &.themeCascadesRouleau {
            .subtitle {
                font-size: 20px;
            }

        }
    }
}
