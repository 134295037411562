@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

$header-small-height: 60px;
$header-height: 80px;
$header-sticky-small-height: 50px;
$header-sticky-height: 60px;

.container {
    position: relative;

    .bar {
        display: flex;
        height: $header-small-height;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $color-white;
    }

    .inner {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        padding: 5px 0;
    }

    .left {
        flex-grow: 1;
    }

    .link {
        display: block;
        padding-right: 10px;
        padding-left: 5px;
    }

    .logo {
        display: block;
        width: auto;
        height: $header-small-height - 10px;

        &.smaller {
            height: $header-small-height - 25px;
        }
    }

    .center {
        position: relative;
        width: 100%;
        height: 100%;

        &.withPopular {
            &::before,
            &::after {
                position: absolute;
                bottom: 0;
                display: block;
                width: 10px;
                height: 100%;
                content: '';
                pointer-events: none;
            }

            &::before {
                z-index: 1;
                left: 0;
                background-image: linear-gradient(
                    90deg,
                    $color-white 10%,
                    rgba($color-white, 0) 90%
                );
            }

            &::after {
                right: 0;
                background-image: linear-gradient(
                    90deg,
                    rgba($color-white, 0) 10%,
                    $color-white 90%
                );
            }

            &.invert {
                &::before {
                    background-image: linear-gradient(90deg, #222 10%, rgba(#222, 0) 90%);
                }

                &::after {
                    background-image: linear-gradient(90deg, rgba(#222, 0) 10%, #222 90%);
                }
            }
        }
    }

    .featuredCard {
        height: $header-small-height;

        .thumbnail {
            width: $header-small-height - 20px;
            height: $header-small-height - 20px;
        }

        .textIcon {
            width: $header-sticky-height;
            height: $header-sticky-height;
        }

        &::after {
            position: absolute;
            z-index: 1;
            right: -10px;
            bottom: 0;
            display: block;
            width: 10px;
            height: 100%;
            background-image: linear-gradient(90deg, $color-white 10%, rgba($color-white, 0) 90%);
            content: '';
            pointer-events: none;
        }
    }

    .popular {
        @extend %hide-scrollbars;

        display: none;
        overflow: hidden;
        width: 100%;
        flex-grow: 1;
        overflow-x: auto;
        visibility: hidden;

        .item {
            min-width: 140px;
            max-width: 250px;
        }

        .thumbnail {
            width: $header-small-height - 10px;
            height: $header-small-height - 10px;
        }
    }

    .right {
        width: 50px;
    }

    &.sticky {
        height: $header-small-height;

        .bar {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: $header-sticky-small-height;
            box-shadow: 0 1px 4px rgba($color-black, 0.2);
        }

        .logo {
            height: $header-sticky-small-height - 10px;

            &.smaller {
                height: $header-sticky-small-height - 20px;
            }
        }

        .featuredCard {
            max-width: 200px;
            height: $header-sticky-small-height;

            .thumbnail {
                width: $header-sticky-small-height - 10px;
                height: $header-sticky-small-height - 10px;
            }

            .textIcon {
                width: $header-sticky-height;
                height: $header-sticky-height;
            }
        }

        .popular {
            font-size: $font-size-normal;

            .thumbnail {
                width: $header-sticky-small-height - 10px;
                height: $header-sticky-small-height - 10px;
            }
        }
    }

    &.transparent {
        .bar {
            background-color: transparent;
            box-shadow: none;
        }

        .center {
            &::before,
            &::after {
                background-image: none;
            }
        }
    }

    @media #{$mq-small-screen} {
        .left {
            flex-grow: 0;
        }

        .center {
            display: flex;
            overflow: hidden;
            flex-direction: row;
            justify-content: center;
        }

        .popular {
            display: flex;
            visibility: visible;
        }
    }

    @media #{$mq-medium-screen} {
        .bar {
            height: $header-height;
            padding: 10px 0;
        }

        .right {
            width: auto;
            margin-left: 10px;
        }

        .link {
            padding-right: 20px;
            padding-left: 10px;
        }

        .logo {
            height: $header-height - 20px;

            &.smaller {
                height: $header-height - 20px;
            }
        }

        .popular {
            .thumbnail {
                width: $header-height - 20px;
                height: $header-height - 20px;
            }
        }

        .featuredCard {
            min-width: 250px;

            .thumbnail {
                width: $header-height - 20px;
                height: $header-height - 20px;
            }

            .textIcon {
                width: $header-height + 25px;
                height: $header-height;
            }
        }

        &.sticky {
            height: $header-height;

            .bar {
                height: $header-sticky-height;
            }

            .logo {
                height: $header-sticky-height - 10px;

                &.smaller {
                    height: $header-sticky-height - 10px;
                }
            }

            .featuredCard {
                min-width: 180px;

                .thumbnail {
                    width: $header-sticky-height - 20px;
                    height: $header-sticky-height - 20px;
                }

                .textIcon {
                    width: $header-sticky-height;
                    height: $header-sticky-height;
                }
            }

            .popular {
                .thumbnail {
                    width: $header-sticky-height - 10px;
                    height: $header-sticky-height - 10px;
                }
            }
        }
    }
}
