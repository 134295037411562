@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    .inner {
        @extend %page-inner-mobile-no-margin;

        display: flex;
        flex-direction: row;
        align-items: center;

        padding: 10px 0;
        border-width: 1px 0;
        border-style: solid;
    }

    .icon {
        width: 90px;
        height: 90px;
    }

    .textBox {
        margin-left: 15px;
    }

    .title {
        @extend %text-newsletter-title;

        font-size: $font-size-medium-small;

        a {
            @extend %text-link;
            color: $color-text;
            font-weight: 500;
        }
    }

    &.withMaxWidth {
        .inner {
            max-width: $max-article-width;
        }
    }

    @media #{$mq-small-screen} {
        .inner {
            padding: 15px 0;
        }

        .icon {
            width: 120px;
            height: 120px;
        }

        .title {
            font-size: $font-size-medium-large;
        }
    }
}
