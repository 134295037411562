@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    position: relative;

    .inner {
        @extend %page-inner-mobile-no-margin;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .more {
        padding-top: 40px;
        padding-bottom: 20px;
    }

    .header {
        padding: 2rem 0;
    }

    .title {
        font-size: $font-size-large-small;
        text-align: center;
    }

    @media #{$mq-medium-screen} {
        .more {
            padding-bottom: 10px;
        }

        .title {
            font-size: $font-size-largest;
        }
    }

    @media #{$mq-large-screen} {
        .more {
            padding-bottom: 0;
        }
    }
}
