@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    position: relative;

    // color: #222;

    .pin {
        display: block;
        width: 100%;
        height: auto;
        color: #fff0e0;
        transition: color 0.5s ease-out;
    }

    &.summer {
        color: #222;

        .pin {
            color: #fff0e0;
        }
    }

    &.winter {
        color: #fff0e0;

        .pin {
            color: #222;
        }
    }

    &.toyota {
        color: #fff;

        .pin {
            color: #f00;
        }
    }

    .icons {
        position: absolute;
        top: 10%;
        left: 50%;
        display: flex;
        width: 60%;
        height: 44%;
        flex-direction: row;
        flex-wrap: wrap;
        color: inherit;
        transform: translate(-50%, 0);

        .icon {
            display: block;
            width: 100%;
            max-width: 100%;
            height: 100%;
            max-height: 100%;
            margin: auto;
            color: inherit;
            transition: color 0.5s ease-out;

            &.destinationsADecouvrir {
                transform: translate(0, 10%);
            }

            &.toyota {
                transform: scale(1.15) translate(0, -15%);
            }

            &.mikaelAnkhone {
                width: 100%;
                max-width: none;
                height: auto;
                max-height: none;
                transform: scale(1.35) translate(2%, 10%);
            }

            &.charles {
                width: 100%;
                max-width: none;
                height: auto;
                max-height: none;
                transform: scale(1.35) translate(-5%, 10%);
            }

            &.audreyAnne {
                width: 100%;
                max-width: none;
                height: auto;
                max-height: none;
                transform: scale(1.3) translate(0, 10%);
            }
        }

        &.many {
            .icon {
                display: block;
                width: 50%;
                height: 50%;

                &.destinationsADecouvrir {
                    transform: translate(0, 0);
                }
            }
        }

        &.two {
            .icon {
                width: 100%;

                &.destinationsADecouvrir {
                    transform: translate(0, 0);
                }
            }
        }

        &.three {
            .icon:last-child {
                width: 100%;

                &.destinationsADecouvrir {
                    transform: translate(0, 0);
                }
            }
        }
    }
}
