@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';
@import '../commons/animations';

.container {
    display: block;
    position: relative;

    .play {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -20px;
        margin-left: -20px;
    }

    .presentedBy {
        position: absolute;
        bottom: 0;
        left: 0;
    }

    &.isFullscreen {
        .embed {
            @extend %fullscreen;
        }
    }
}
