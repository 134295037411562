@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    border-color: #000;

    .inner {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
    }

    .content {
        flex-grow: 1;
    }

    .icon {
        width: 40px;
        min-width: 40px;
        margin-right: 10px;
    }

    .name {
        margin-bottom: 0.2em;
        font-family: inherit;
        font-size: 1.3em;
        font-weight: 500;
    }

    .address {
        font-weight: 300;
        line-height: 1.3;
    }

    .button {
        display: inline-block;
        padding: 0.5em;
        border: solid 1px;
        margin-top: 1em;
        background-color: #f8eee3;
        border-radius: 2em;
        font-size: 0.8em;
        letter-spacing: 0.02em;
        white-space: nowrap;
    }
}
