@import '../commons/variables';
@import '../commons/placeholders';

.container {
    @extend %reset-button;

    display: inline-block;
    width: 50px;
    padding: 10px;

    .inner {
        position: relative;
        display: block;
        width: 100%;
        height: 18px;
    }

    .bars {
        @extend %fullscreen;
    }

    .bar, .otherBar {
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        width: 100%;
        height: 3px;
        border-radius: 5px;
        transform: translate(0, -50%);
        transform-origin: center;
        transition: opacity 0.2s ease-out, transform 0.2s ease-out;

        &.first {
            top: 0;
            transform: translate(0, 0);
        }

        &.last {
            top: auto;
            bottom: 0;
            margin-bottom: 0;
            transform: translate(0, 0);
        }

        &.otherBar {
            opacity: 0;
        }
    }

    &.isActive {
        .bar {
            opacity: 1;
            transform: rotate(-45deg);
        }

        .otherBar {
            opacity: 1;
            transform: rotate(45deg);
        }

        .first {
            opacity: 0;

        }

        .last {
            opacity: 0;
        }
    }
}
