@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

/* Bug with prettier and calc */
/* stylelint-disable */
$headerSmallHeight: 40px;
$headerHeight: 60px;
$headerStickySmallHeight: 30px;
$headerStickyHeight: 40px;
/* stylelint-enable */

.container {
    position: relative;

    .bar {
        display: flex;
        height: $headerSmallHeight;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #ff0;
    }

    .inner {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        padding: 5px 0;
    }

    .logo {
        display: block;
        width: auto;
        height: calc($headerSmallHeight / 2);
    }

    .center {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: center;
    }

    .right {
        // width: auto;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translate(0, -50%);

        // padding-right: 20px;
        // margin-left: 10px;
    }

    .button {
        position: relative;
        background-color: $color-white;
        border-radius: 50%;

        &::after {
            position: absolute;
            top: -4px;
            left: -4px;
            width: calc(100% + 8px);
            height: calc(100% + 8px);
            border: 4px solid rgba($color-white, 0.5);
            border-radius: 50%;
            content: '';
        }
    }

    &.dehors {
        .bar {
            background-color: #00c693;
            background-image: linear-gradient(45.14deg, #00c693 0%, #cbf518 100%);
        }

        .logo {
            color: $color-white;
        }
    }

    &.quatre95 {
        .bar {
            background-color: #5e79ff;
        }

        .logo {
            height: calc($headerSmallHeight / 4);
            color: $color-white;
        }
    }

    &.universities {
        .bar {
            background-color: $color-white;
        }
    }

    &.france {
        .logo {
            width: auto;
            height: 100%;
            max-height: $headerSmallHeight;
            margin: 0 auto;
        }
    }

    &.sticky {
        .bar {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            box-shadow: 0 3px 6px rgba($color-black, 0.2);
        }
    }

    &.transparent {
        .bar {
            background-color: transparent;
            background-image: none;
            box-shadow: none;
        }
    }

    // theme
    &.card {
        .logo {
            color: $color-black;
        }
    }

    @media #{$mq-medium-screen} {
        .bar {
            height: $headerHeight;
            padding: 10px 0;
        }

        .logo {
            height: calc($headerHeight / 2);
        }

        &.quatre95 {
            .logo {
                height: calc($headerHeight / 2);
            }
        }

        &.sticky {
            height: $headerHeight;

            .bar {
                height: $headerStickyHeight;
                padding: 10px 0;
            }

            .logo {
                height: calc($headerStickyHeight / 2);
            }
        }
    }
}
