%image-effect {
    transform: scale(1);
    transition: filter $default-easing-time $default-easing-function,
        transform $default-easing-time $default-easing-function;

    &:hover {
        filter: contrast(112%);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes loading-iddle {
    0% {
        width: 0%;
    }

    100% {
        width: 90%;
    }
}

@keyframes opening {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes float {
    from {
        transform: translateX(5%);
        transform: translateY(-15%);
    }

    to {
        transform: translateX(-5%);
        transform: translateY(15%);
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes wiggle {
    0% {
        transform: rotate(0);
    }

    35% {
        transform: rotate(0);
    }

    40% {
        transform: rotate(6deg);
    }

    45% {
        transform: rotate(0);
    }

    50% {
        transform: rotate(4deg);
    }

    55% {
        transform: rotate(0);
    }

    60% {
        transform: rotate(2deg);
    }

    65% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(0);
    }
}
