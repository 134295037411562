%inherit-text-style {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    text-transform: inherit;
}

/**
 * Containers
 */
%fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/**
 * Controls
 */
%reset-button {
    @extend %inherit-text-style;

    position: relative;
    display: inline-block;
    padding: 0;
    border: 0;
    appearance: none;
    background: transparent;
    color: inherit;
    cursor: pointer;
}

%reset-input {
    @extend %inherit-text-style;

    padding: 0;
    border: 0;
    appearance: none;
    background: transparent;
    border-radius: 0;
    color: inherit;
    cursor: pointer;
}

%control-label {
    @extend %brand-text-style;

    display: block;
    // color: $color-gray;
    font-weight: normal;
}

%control-basic {
    // height: calc(3em + 2px);
    padding: 0.75em;
    border: 1px solid black;
    border-radius: 0.5em;
    font-family: $font-slab-tight;
    font-weight: 500;
}

%button-basic {
    @extend %control-basic;

    background: $color-black;
    color: $color-white;

    &::disabled {
        opacity: 0.8;
    }
}

%input-basic {
    @extend %control-basic;

    background: $color-white;
    color: #000;

    &::placeholder {
        color: rgba(#000, 0.5);
    }
}

%control-small {
    padding: 0.5em 1em;
    font-size: 0.75em;
}

%input-small {
    @extend %control-small;
}

%button-small {
    @extend %control-small;
}

%form-box {
    padding: 40px 10px;
    background: $color-white;
    box-shadow: 2px 2px 10px rgba($color-black, 0.2);
    font-family: $font-slab;

    @media #{$mq-small-screen} {
        padding: 40px;
        border-radius: 20px;
    }

    @media #{$mq-medium-screen} {
        padding: 40px;
        border-radius: 20px;
    }
}

%hide-scrollbars {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
    }
}

%visually-hidden {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    white-space: nowrap;
}

/**
 * Layout
 */

%page-inner {
    width: 100%;
    max-width: $max-page-width;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;

    @media #{$mq-large-screen} {
        padding-right: 0;
        padding-left: 0;
    }
}

%page-inner-mobile-no-margin {
    @extend %page-inner;

    padding-right: 0;
    padding-left: 0;

    @media #{$mq-medium-screen} {
        padding-right: 10px;
        padding-left: 10px;
    }

    @media #{$mq-large-screen} {
        padding-right: 0;
        padding-left: 0;
    }
}

%page-small-inner {
    @extend %page-inner;

    max-width: $max-article-width;
}

%article-inner {
    @extend %page-inner;

    max-width: $max-article-width;
}

%grid {
    display: grid;
    max-width: 100%;
    margin: 0;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    grid-template-columns: repeat(1, minmax(100%, 1fr));

    .more {
        display: block;
        text-align: center;
    }

    @media #{$mq-medium-screen} {
        margin: 0;
        grid-template-columns: repeat(2, minmax(320px, 1fr));
    }

    @media #{$mq-large-screen} {
        grid-template-columns: repeat(3, minmax(320px, 1fr));
    }
}

/**
 * Elements
 */

%image {
    display: block;
    overflow: hidden;
    max-width: 100%;
    transform: scale(1);
    transition: transform $default-easing-time $default-easing-function;
}

%link-hover {
    transition: opacity $default-easing-time $default-easing-function;

    &:hover {
        opacity: 0.8;
    }
}

/**
 * Text
 */
%font-smoothing {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

%brand-text-style {
    font-family: $font-other;
    font-weight: 700;
    text-transform: uppercase;
}

%text-clamp {
    // stylelint-disable-next-line value-no-vendor-prefix
    display: -webkit-box;
    overflow: hidden;
    max-height: 2.5em;
    // stylelint-disable-next-line property-no-vendor-prefix
    -webkit-box-orient: vertical;
    line-height: 1.1;
    text-overflow: ellipsis;
}

%text-clamp-2 {
    @extend %text-clamp;

    -webkit-line-clamp: 2;
}

%text-heading-style {
    @extend %font-smoothing;

    font-family: $font-title;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
}

%text-heading {
    @extend %text-heading-style;

    font-size: $font-size-large;

    @media #{$mq-medium-screen} {
        font-size: $font-size-larger;
    }

    @media #{$mq-large-screen} {
        font-size: $font-size-largest;
    }
}

%text-subtitle {
    font-family: $font-text;
    font-size: $font-size-normal-large;
    font-style: italic;
    font-weight: 500;
    line-height: 1.3;
    text-transform: none;
}

%article-category {
    display: inline-block;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: inherit;
}

%text-tags-style {
    strong,
    b {
        font-weight: 700;
    }

    em,
    i {
        font-style: italic;
    }

    sup {
        display: inline-block;
        font-size: 0.8em;
        line-height: 1;
        vertical-align: top;
    }
}

%text-author-name {
    @extend %brand-text-style;

    max-width: 15em;
    letter-spacing: 0.01em;
    word-break: keep-all;
}

%text-author-description {
    @extend %font-smoothing;
    @extend %text-tags-style;

    max-width: 30em;
    font-family: $font-text;
    letter-spacing: 0.01em;
    line-height: 1.2;

    a {
        @extend %text-link;

        background-image: linear-gradient(0deg, rgba(#fff, 0.2) 0, rgba(#fff, 0.25));
        font-weight: 700;
        text-decoration: underline;
        text-transform: uppercase;
    }
}

%text-link {
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-size: 100% 40%;
    transition: background-size 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 75ms;

    &:hover {
        background-size: 100% 100%;
    }
}

%text-quote {
    font-family: $font-title;
    font-size: $font-size-large-small;
    font-weight: 700;
    line-height: 1.05;
    text-transform: uppercase;

    @media #{$mq-medium-screen} {
        font-size: $font-size-large;
    }

    @media #{$mq-large-screen} {
        font-size: $font-size-large;
    }
}

%text-body {
    @extend %font-smoothing;
    @extend %text-tags-style;

    font-family: $font-text;
    font-kerning: auto;
    font-size: $font-size-medium-small;
    font-variant: common-ligatures;
    font-weight: 400;
    letter-spacing: 0.01em;
    line-height: 1.3;
    text-rendering: optimizelegibility;

    p,
    blockquote {
        margin-bottom: 1em;

        &:last-child {
            margin-bottom: 0;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @extend %brand-text-style;

        margin-bottom: 0.5rem;
        line-height: 1.3;

        em,
        i {
            font-style: italic;
        }
    }

    h1 {
        font-size: $font-size-medium-larger;
    }

    h2 {
        font-size: $font-size-medium-large;
    }

    h3 {
        font-size: $font-size-medium;
    }

    h4 {
        font-size: $font-size-medium-small;
    }

    h5 {
        font-size: $font-size-normal-large;
    }

    h6 {
        font-size: $font-size-normal;
    }

    p a,
    li a {
        @extend %text-link;
    }

    img {
        max-width: 100%;
    }

    ol {
        padding-left: 1.5em;
        list-style: decimal;
        list-style-position: inside;

        li {
            padding-left: 0.5em;
            margin-bottom: 0.5em;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    ul {
        padding-left: 1.5em;
        list-style: square;
        list-style-position: inside;

        li {
            padding-left: 0.5em;
            margin-bottom: 0.5em;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    :global {
        .small {
            font-size: 0.85em;

            h1,
            h2,
            h3 {
                font-size: 1em;
            }

            h4,
            h5,
            h6 {
                font-size: 1.1em;
            }
        }
    }
}

%text-newsletter-title {
    @extend %font-smoothing;

    font-family: $font-title;
    font-size: $font-size-medium-large;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
}

%text-newsletter-text {
    @extend %font-smoothing;

    font-family: $font-text;
    font-size: $font-size-normal;
    line-height: 1.3;
}

/**
 * Elements
 **/
%magazine-issue-number {
    display: flex;
    width: 2.5em;
    height: 2.5em;
    align-items: center;
    justify-content: center;
    border: solid 4px $color-black;
    background: $color-white;
    border-radius: 1.5em;
    color: $color-black;
    font-family: $font-other;
    font-weight: bold;
    text-align: center;
}

/**
 * Hydro
 **/

%img-description {
    position: absolute;
    color: $color-black;
    font-family: $font-other;
    font-size: $font-size-smallest;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}

%quote {
    padding: 15px;
    color: $color-hydro-blue;
    font-family: $font-other;
    font-size: $font-size-medium;
    font-weight: bold;
}

/**
 * Bnc
 **/

%button-bnc-article {
    @extend %reset-button;

    position: relative;
    width: 90px;
    height: 90px;
    padding: 2px;
    background-color: $color-q95-blue;
    border-radius: 50%;
    box-shadow: -3px 3px #3862f9;
    color: $color-white;
    font-family: $font-title;
    font-size: $font-size-normal;
    line-height: 1.3;
    text-transform: uppercase;
    transition: transform 0.1s linear, box-shadow 0.1s linear;

    &:hover {
        box-shadow: -2px 2px #3862f9;
        transform: translate(-1px, 1px);
    }

    &:active {
        box-shadow: 0 0 #3862f9;
        transform: translate(-4px, 4px);
    }

    &.submit {
        font-size: $font-size-medium;
    }
}

%success-message {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    margin: 0 auto;
    color: $color-q95-yellow;
    font-family: $font-quatre95;
    font-size: $font-size-larger;
    font-weight: 700;
    pointer-events: none;
    text-align: center;
    text-transform: uppercase;
    transform: translateY(-50%);
    transition: opacity 0.3s ease;
}
