@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    display: flex;
    align-items: center;
    justify-content: center;

    .link {
        display: inline-block;
    }

    .logo {
        display: block;
        width: auto;
        height: 2em;
        margin-top: 10px;
    }

    .button {
        position: absolute;
        top: 10px;
        right: 0;
        display: block;
        width: 50px;
        min-width: 50px;
        padding: 10px;
    }
}
